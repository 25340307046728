import moment from "moment";
import { useEffect, useState } from "react";
// import * as XLSX from 'xlsx';
import UploadFile from '../Common/UploadFile';
import TableInput from './components/TableInput';

export default function Home2(props) {
  const [dataAllPage, setDataAllPage] = useState(props?.dataSave)
  const [pageActive, setPageActive] = useState(0)
  const [nameFileExport, setNameFileExport] = useState('')
  const [tenquyen, setTenquyen] = useState('')

  useEffect(() => {
    const dateAllPageOld = localStorage.getItem('dataAllPage') ? JSON.parse(localStorage.getItem('dataAllPage')) : []
    setDataAllPage([...dateAllPageOld])
    setNameFileExport(localStorage.getItem('nameFile'))
    setTenquyen(localStorage.getItem('tenquyen'))
  }, [])

  useEffect(() => {
    if (props?.dataInsert) {
      props?.dataInsert(dataAllPage)
    }
  }, [dataAllPage])

  const handleChangeValueInput = (value, setDataValue, name) => {
    setDataValue(value)
    localStorage.setItem(name, value)
  }

  const fileNameExport = `${nameFileExport ? nameFileExport : 'filename'}_${moment().unix()}`

  const handleAddPage = () => {
    dataAllPage.push({})
    setDataAllPage([...dataAllPage])
  }

  function convertToSlug(str) {
    // str = str.toLowerCase();
    str = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    str = str.replace(/[đĐ]/g, 'd');
    // str = str.replace(/([^0-9a-z-\s])/g, '');
    str = str.replace(/(\s+)/g, '_');
    str = str.replace(/-+/g, '_');
    // str = str.replace(/^-+|-+$/g, '');
    return str;
  }

  const handleRemoveAll = () => {
    if (window.confirm("Bạn có muốn làm mới? Sẽ bị mất hết dữ liệu nếu đồng ý.")) {
      const res = localStorage.getItem('dataAllPage')
      localStorage.setItem('dataAllPageOld', res)
      localStorage.removeItem('dataAllPage')
      localStorage.removeItem('nameFile')
      localStorage.removeItem('tenquyen')
      window.location.reload();
    }

  }

  const handleRemoveSheet = () => {
    let dataAllPageOld = dataAllPage
    dataAllPageOld.pop();
    setDataAllPage([...dataAllPageOld])
    localStorage.setItem('dataAllPage', JSON.stringify(dataAllPageOld))
  }

  const handleExportFileData = async () => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(dataAllPage)
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = `${fileNameExport}_${moment().unix()}.json`;

    link.click();
  }

  const handleChangeImportFile = (event) => {
    var fr = new FileReader();
    fr.onload = function () {
      const dataImportPage = JSON.parse(fr.result)
      setDataAllPage(dataImportPage)
    }
    fr.readAsText(event.target.files[0]);
  }

  const handleSaveImportExcel = (value) => {
    setDataAllPage([...value])
    localStorage.setItem('dataAllPage', JSON.stringify(value))
  }

  const handleDeleteItem = (key) => {
    delete dataAllPage[key]
    var filtered = dataAllPage.filter(function (el) {
      return el != null;
    });
    localStorage.setItem('dataAllPage', JSON.stringify(filtered))
    setDataAllPage([...filtered])
  }

  return (
    <div style={{ padding: 0 }}>
      <UploadFile params={{ id: 1 }} dataPush={(value) => handleSaveImportExcel(value)} />
      <div style={{ marginTop: 10 }}>
        <input type={'file'} onChange={(event) => handleChangeImportFile(event)} />
        <button className="vowvrqnxli" onClick={() => handleRemoveAll()}>Xóa hết dữ liệu</button>
        <button className="vowvrqnxli" onClick={() => handleExportFileData()}>Save File</button>
      </div>
      <button className="vowvrqnxli" onClick={() => handleRemoveSheet()}>Xóa sheet</button>
      {/* <button className="vowvrqnxli" onClick={() => exportFile()}>Export to Excel</button> */}
      <button className="vowvrqnxli" onClick={() => handleAddPage()}>Thêm trang</button>
      <input value={nameFileExport} onChange={(event) => handleChangeValueInput(event.target.value, setNameFileExport, 'nameFile')} placeholder="Tên file xuất" />
      <input value={tenquyen} onChange={(event) => handleChangeValueInput(event.target.value, setTenquyen, 'tenquyen')} placeholder="Tên quyển nhập" />
      <div className={`ioogqnyxhj`}>
        {
          dataAllPage && dataAllPage.length > 0 ? dataAllPage.map((item, key) => {
            return (
              <div className={`pgxnslbqhg ${pageActive == key ? 'twbifjlqvc' : ''}`} key={key}>
                <div onClick={() => setPageActive(key)}>
                  {item?.fullname1 ? convertToSlug(item.fullname1).toUpperCase() + `_TRANG_${item?.page_sheet}` : `SHEET_${key + 1}`}
                </div>
                <div className="tfrftoubdd" onClick={() => handleDeleteItem(key)}>X</div>
              </div>
            )
          }) : null
        }
      </div>
      {
        dataAllPage.length > 0 ? <TableInput UpdateData={(value) => {
          dataAllPage[pageActive] = value
          setDataAllPage([...dataAllPage])
          localStorage.setItem('dataAllPage', JSON.stringify(dataAllPage))
        }} pageActive={pageActive} dataAllPage={dataAllPage} item={dataAllPage[pageActive]} /> : null
      }

      {/* {
        dataAllPage && dataAllPage.length > 0 ? dataAllPage.map((item, key) => {
          return (
            <Table pageActive={pageActive} item={item} keyValue={key} />
          )
        }) : null
      } */}
      {/* <TableMenu dataAllPage={dataAllPage} tenquyen={tenquyen} /> */}
    </div>
  )
}