import { Button, Form, Select, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import API from '../../service/API'
import { slugify } from '../../Helper/helpFunction'

export default function LinkThon(props) {
  const village = props?.village
  const [ParcelOfLandList, setParcelOfLandList] = useState({})
  // const ParcelOfLandList = props?.ParcelOfLandList
  // const setParcelOfLandList = props?.setParcelOfLandList
  const keyttt = props?.keyttt
  const ttt = props?.ttt
  const [ShowEdit, setShowEdit] = useState(false)
  const [Loading, setLoading] = useState(false)

  useEffect(() => {
    if (ShowEdit) {
      handleChangeSelect(ttt?.thonlinktosheet, keyttt)
    }
  }, [ShowEdit])

  const handleChangeSelect = async (value, keyRow) => {
    setLoading(true)
    if (value) {
      const res = await await API.ParcelOfLand.getData({ village_id: value, limit: 500, page: 1, orderByName: 'page_sheet', orderBySort: 'asc' })

      if (res?.status === 200) {
        if (!ParcelOfLandList[keyRow]) { ParcelOfLandList[keyRow] = {} }
        ParcelOfLandList[keyRow].thuadatlinktosheet = res?.data
        setParcelOfLandList({ ...ParcelOfLandList })
      }
    }
    setLoading(false)
  }

  return (
    <>
      <div>
        <Button danger onClick={() => setShowEdit(!ShowEdit)}>Edit</Button>
      </div>
      {
        ShowEdit ? <>
          <Spin spinning={Loading}>
            <div>Thôn</div>
            <Form.Item name={['thaydoi', keyttt, `thonlinktosheet`]}>
              <Select allowClear style={{ width: '100%' }}
                showSearch
                filterOption={(input, option) => {
                  if (option?.children) {
                    return slugify(option.children).toLowerCase().indexOf(slugify(input).toLowerCase()) >= 0
                  }
                }}
                onChange={(value) => handleChangeSelect(value, keyttt)}>
                <Select.Option value={``}>Lựa chọn thôn</Select.Option>
                {
                  village?.data && village?.data.length ? village?.data.map((item, key) => {
                    return <Select.Option key={key} value={`${item.id}`}>
                      {item?.name}
                    </Select.Option>
                  }) : null
                }
              </Select>
            </Form.Item>
            <div>Thửa</div>
            <Form.Item name={['thaydoi', keyttt, `thuadatlinktosheet`]}>
              <Select allowClear style={{ width: '100%' }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => {
                  if (option?.children) {
                    return slugify(option.children).toLowerCase().indexOf(slugify(input).toLowerCase()) >= 0
                  }
                }}
                filterSort={(optionA, optionB) => {
                  if (optionA?.children && optionB?.children) {
                    JSON.stringify(optionA.children).toLowerCase().localeCompare(JSON.stringify(optionB.children).toLowerCase())
                  }
                }}>
                {
                  ParcelOfLandList?.[keyttt]?.thuadatlinktosheet && ParcelOfLandList?.[keyttt]?.thuadatlinktosheet.length ?
                    ParcelOfLandList?.[keyttt]?.thuadatlinktosheet.map((item, key) => {
                      return <Select.Option key={key} value={`${item.id}`}>
                        Trang: {item?.page_sheet} - {item?.fullname1 ? item?.fullname1 : ''} - {item?.fullname2 ? item?.fullname2 : ''}
                      </Select.Option>
                    }) : null
                }
              </Select>
            </Form.Item>
          </Spin>
        </> : <>
          <div>Thôn: {ttt?.thonlinktosheet_detail?.name ? ttt?.thonlinktosheet_detail?.name : ''}</div>
          <div>Thửa: <a href={`/admin/parcelland/index/${ttt?.thonlinktosheet}`}>{ttt?.thuadatlinktosheet_detail?.fullname1 ? ttt?.thuadatlinktosheet_detail?.fullname1 : ''}</a></div>
          <div>địa chỉ: <a href={`/admin/parcelland/index/${ttt?.thonlinktosheet}`}>{ttt?.thuadatlinktosheet_detail?.address ? ttt?.thuadatlinktosheet_detail?.address : ''}</a></div>
        </>
      }
    </>
  )
}
