import {
    HddOutlined, InsertRowLeftOutlined, SettingOutlined,
    TrademarkCircleOutlined, UserOutlined
} from '@ant-design/icons';
import { Tag } from 'antd';
import React from "react";


export const LOGIN_ADMIN = 'LOGIN_ADMIN';
export const LOGIN_CUSTOMER = 'LOGIN_CUSTOMER';
export const LOGOUT_ADMIN = 'LOGOUT_ADMIN';

export const ACTION_DO = 'ACTION_DO';
export const ACTION_STOP = 'ACTION_STOP';

export const RouterLink = {
    Home: '/admin/index',
    Wards: {
        Home: '/admin/index',
        List: '/admin/wards/index',
        Create: '/admin/wards/create',
        Edit: id => {
            return `/admin/wards/edit/${id}`
        },
        Export: id => {
            return `/wards/export/${id}`
        },
    },
    Village: {
        Home: '/admin/index',
        List: '/admin/village/index',
        Create: '/admin/village/create',
        Edit: id => {
            return `/admin/village/edit/${id}`
        },
        View: id => {
            return `/admin/village/index/${id}`
        },
    },
    ParcelOfLand: {
        Home: '/admin/index',
        List: '/admin/parcelland/index',
        Create: '/admin/parcelland/create',
        Edit: id => {
            return `/admin/parcelland/edit/${id}`
        },
        View: id => {
            return `/admin/parcelland/index/${id}`
        },
    },
    ParcelOfLandMore: {
        Home: '/admin/index',
        List: '/admin/parcellandmore/index',
        Create: '/admin/parcellandmore/create',
        Edit: id => {
            return `/admin/parcellandmore/edit/${id}`
        },
    },
    ParcelOfLandInformation: {
        Home: '/admin/index',
        List: '/admin/parcellandinformation/index',
        Create: '/admin/parcellandinformation/create',
        Edit: id => {
            return `/admin/parcellandinformation/edit/${id}`
        },
    },
    Permission: {
        // setting: '/admin/permission/setting',
        list: '/admin/permission/list',
        Edit: id => {
            return `/admin/permission/edit/${id}`
        },
        create: '/admin/permission/create',
    },
};

export const ListMenuDefault = [
    // {
    //     title: 'Phân quyền',
    //     icon: <HddOutlined />,
    //     link: null,
    //     permissionLink: ['admin/permission/list', 'admin/permission/create', 'admin/permission/edit'],
    //     children: [
    //         {
    //             'title': 'Danh sách nhóm quyền', 'icon': <UserOutlined />, 'link': RouterLink.Permission.list,
    //             permissionLink: ['admin/permission/list']
    //         },
    //         {
    //             'title': 'Tạo nhóm quyền', 'icon': <UserOutlined />, 'link': RouterLink.Permission.create,
    //             permissionLink: ['admin/permission/create']
    //         },
    //     ],
    // },
    {
        title: 'Quản trị thông tin thôn',
        icon: <InsertRowLeftOutlined />,
        link: null,
        permissionLink: ['admin/village/list', 'admin/village/list', 'admin/parcelland/list'],
        children: [
            { 'title': 'Thông tin xã', 'icon': <UserOutlined />, 'link': RouterLink.Wards.List, permissionLink: ['admin/wards/list'] },
            { 'title': 'Thông tin thôn', 'icon': <UserOutlined />, 'link': RouterLink.Village.List, permissionLink: ['admin/village/list'] },
            { 'title': 'Thông tin thửa', 'icon': <UserOutlined />, 'link': RouterLink.ParcelOfLand.List, permissionLink: ['admin/parcelland/list'] },
        ],
    },
];


export const configCkeditor5 = {
    cloudServices: {
        tokenUrl: "https://33333.cke-cs.com/token/dev/ijrDsqFix838Gh3wGO3F77FSW94BwcLXprJ4APSp3XQ26xsUHTi0jcb1hoBt",
        uploadUrl: "https://33333.cke-cs.com/easyimage/upload/"
    },
    image: {
        styles: [
            'alignLeft', 'alignCenter', 'alignRight'
        ],
        toolbar: [
            'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight',
            '|',
            'imageTextAlternative',
        ]
    },
    table: {
        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
    }
}
