import 'antd/dist/antd.css';
import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import HomeAdmin from "./pages/HomeAdmin/HomeAdmin";
import LayoutApp from "./pages/Layout/LayoutApp";
import Login from "./pages/Login/Login";
import PageExcel from "./pages/PageExcel/Home";

import WardsList from './pages/Wards/list';
import WardsCreate from './pages/Wards/create';
import WardsEdit from './pages/Wards/edit';

import VillageList from './pages/Village/list';
import VillageCreate from './pages/Village/create';
import VillageEdit from './pages/Village/edit';

import ParcelOfLandList from './pages/ParcelOfLand/list';
import ParcelOfLandCreate from './pages/ParcelOfLand/create';
import ParcelOfLandEdit from './pages/ParcelOfLand/edit';
import ParcelPrint from './pages/ParcelOfLand/ParcelPrint';

import Export from './pages/Export/Export';
import Danhmuc from './pages/Export/Danhmuc';
import ExportWard from './pages/Wards/ExportWard';


const Prefix = 'admin';
export default function Router() {
    const currentUser = useSelector(state => state?.currentUser);

    const RouterAdminOutSite = (props) => {
        return (
            currentUser === null ?
                <Route path={props.path} exact={props.exact} component={props.component} />
                : <Redirect to='/admin/index' />
        )
    };

    const RouterAdminDash = (props) => {
        return (
            currentUser !== null ?
                <Route path={props.path} exact={props.exact} component={props.component} />
                : <Redirect to='/admin/login' />
        )
    };

    useEffect(() => {

    }, [])

    return (
        <BrowserRouter>
            <Switch>
                <Route exact path={`/${Prefix}/village/export2/:id/:page`} component={Export} />
                <Route exact path={`/${Prefix}/village/export2/:id`} component={Export} />
                <Route exact path={`/${Prefix}/village/danhmuc/:id`} component={Danhmuc} />
                <Route exact path={`/${Prefix}/page_excel`} component={PageExcel} />
                <Route exact path={`/${Prefix}/wards/export/:id`} component={ExportWard} />
                <RouterAdminOutSite exact path={`/${Prefix}/login`} component={Login} />
                <LayoutApp>
                    <RouterAdminDash exact path={`/${Prefix}/index`} component={HomeAdmin} />

                    <RouterAdminDash exact path={`/${Prefix}/wards/index`} component={WardsList} />
                    <RouterAdminDash exact path={`/${Prefix}/wards/create`} component={WardsCreate} />
                    <RouterAdminDash exact path={`/${Prefix}/wards/edit/:id`} component={WardsEdit} />

                    <RouterAdminDash exact path={`/${Prefix}/village/index/:id`} component={VillageList} />
                    <RouterAdminDash exact path={`/${Prefix}/village/index`} component={VillageList} />
                    <RouterAdminDash exact path={`/${Prefix}/village/create`} component={VillageCreate} />
                    <RouterAdminDash exact path={`/${Prefix}/village/edit/:id`} component={VillageEdit} />

                    <RouterAdminDash exact path={`/${Prefix}/parcelland/index/:id`} component={ParcelOfLandList} />
                    <RouterAdminDash exact path={`/${Prefix}/parcelland/index`} component={ParcelOfLandList} />
                    <RouterAdminDash exact path={`/${Prefix}/parcelland/create`} component={ParcelOfLandCreate} />
                    <RouterAdminDash exact path={`/${Prefix}/parcelland/edit/:id`} component={ParcelOfLandEdit} />

                    <RouterAdminDash exact path={`/${Prefix}/parcelland/print/:id`} component={ParcelPrint} />
                </LayoutApp>
            </Switch>
        </BrowserRouter>
    );
}
