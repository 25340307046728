import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload, message } from "antd";
import { useState } from 'react';
import API from '../../service/API';
import Excel from 'exceljs'
import moment from 'moment';
import { slugify } from '../../Helper/helpFunction';

export default function UploadFile({ params, maxFile = 1, dataPush }) {
  const [fileList, setLileList] = useState([])
  const [uploading, setUploading] = useState(false)
  const [DataListNew, setDataListNew] = useState([])

  const handleUpload = async () => {
    if (fileList?.[0]?.originFileObj) {
      // const formData = new FormData();
      // fileList?.fileList.map(file => {
      //   formData.append('files[]', file);
      // });
      let listData = [];
      const wb = new Excel.Workbook();
      const reader = new FileReader()
      // setUploading(true)
      reader.readAsArrayBuffer(fileList?.[0]?.originFileObj)
      reader.onload = () => {
        const buffer = reader.result;
        wb.xlsx.load(buffer).then(workbook => {
          workbook.eachSheet((sheet, id) => {
            let trang = ''
            let fullname1 = ''
            let cmnd1 = ''
            let age1 = ''
            let fullname2 = ''
            let cmnd2 = ''
            let age2 = ''
            let diachi = ''
            let thuadat = []
            let getThuadat = true
            let thaydoi = []
            let getThongtinthaydoi = false

            if (sheet?.state === 'visible') {
              let totalLength = 0
              let listDataRow = []
              sheet.eachRow((row, rowIndex) => {
                let valueFirst = row?.values?.[1]
                if (valueFirst && typeof valueFirst === 'string') {
                  valueFirst = valueFirst.toLocaleLowerCase()
                  valueFirst = valueFirst.replaceAll(' ', '')

                  if (valueFirst?.indexOf('i-ngườisửdụngđất') > -1) {
                    totalLength++;
                  }
                }

                listDataRow.push(row?.values)
              })
              for (let index = 0; index <= totalLength; index++) {
                let totalRow = 68 * index
                // console.log(totalRow, 2 + totalRow, listDataRow, 'totalRow');

                let value1 = listDataRow?.[0 + totalRow] || []
                const trang = makeRow1(value1)?.trang;
                let value2 = listDataRow?.[2 + totalRow] || []
                const dateUser = makeRow3(value2);

                const fullname1 = dateUser?.fullname_version1?.[0] || ''
                const fullname2 = dateUser?.fullname_version1?.[1] || ''
                const age1 = dateUser?.age_version1?.[0] || ''
                const age2 = dateUser?.age_version1?.[1] || ''
                const cmnd1 = dateUser?.cmnd_version1?.[0] || ''
                const cmnd2 = dateUser?.cmnd_version1?.[(dateUser?.cmnd_version1?.length - 1) || 0] || ''
                let value5 = listDataRow?.[5 + totalRow] || []
                const diachi = makeRow5(value5)?.diachi;

                const thuadat = []
                const thaydoi = []
                // if (makeData(row?.values, rowIndex)?.diachi) { diachi = makeData(row?.values, rowIndex)?.diachi; }

                for (let index = 10 + totalRow; index <= 36 + totalRow; index++) {
                  let itemRow = listDataRow[index]
                  const DataRow = makeRowThuadat(itemRow)
                  thuadat.push(DataRow)
                }
                for (let index = 38 + totalRow; index <= 66 + totalRow; index++) {
                  let itemRow = listDataRow[index]
                  const DataRow = makeRowThaydoi(itemRow)
                  thaydoi.push(DataRow)
                }

                if (fullname1 || fullname2 || cmnd1 || cmnd2 || age1 || age2) {
                  listData.push({
                    trang: trang,
                    fullname1: fullname1,
                    cmnd1: cmnd1,
                    age1: age1,
                    fullname2: fullname2,
                    cmnd2: cmnd2,
                    age2: age2,
                    address: diachi,
                    note: '',
                    thuadat: thuadat,
                    thaydoi: thaydoi,
                    page_sheet: trang,
                  })
                }
              }
            }
          })
          dataPush(listData)
        })
      }
    }
  };

  const makeRow1 = (value) => {
    let text = value?.[value?.length - 1] || ''
    text = text.replaceAll('trang', '')
    text = text.replaceAll('Trang', '')
    text = text.replaceAll('số', '')
    text = text.replaceAll(':', '')
    text = text.replaceAll(' ', '')

    return {
      trang: text
    }
  }
  const removeText = (item) => {
    if ((item.toLowerCase()) === 'hộ') { return null }
    if ((item.toLowerCase()) === 'ông:') { return null }
    if ((item.toLowerCase()) === 'sinh') { return null }
    if ((item.toLowerCase()) === 'năm') { return null }
    if ((item.toLowerCase()) === 'năm:') { return null }
    if ((item.toLowerCase()) === 'cmnd') { return null }
    if ((item.toLowerCase()) === 'số') { return null }
    if ((item.toLowerCase()) === 'số:') { return null }
    if ((item.toLowerCase()) === 'và') { return null }
    if ((item.toLowerCase()) === 'bà') { return null }
    if ((item.toLowerCase()) === 'bà:') { return null }
    if ((item.toLowerCase()) === 'địa') { return null }
    if ((item.toLowerCase()) === 'chỉ') { return null }
    if ((item.toLowerCase()) === 'chỉ:') { return null }
    return item
  }
  const makeRow3 = (value, type) => {
    let text = value?.[1]
    if (!text) {
      return null
    }
    let textFull = ''
    let listText = text.split(' ')
    listText = listText.filter((item) => {
      let valueText = item
      valueText = valueText.replaceAll("họ", '')
      valueText = item.trim()

      if (valueText &&
        valueText !== '-' &&
        valueText !== 'họ' &&
        valueText !== 'tên' &&
        valueText !== 'hoặc' &&
        valueText !== 'chồng)' &&
        valueText !== 'sinh' &&
        valueText !== 'năm' &&
        valueText !== 'năm:' &&
        valueText !== 'null' &&
        valueText !== 'null,' &&
        valueText !== 'cmnd:' &&
        valueText !== 'cmnd' &&
        valueText !== 'chồng' &&
        valueText !== 'Ông/Bà:' &&
        valueText !== 'số' &&
        valueText !== '(' &&
        valueText !== ')'
      ) {
        textFull = textFull + ' ' + valueText
      }
    })
    textFull = textFull.replaceAll('họ', '')
    textFull = textFull.replaceAll(',', '')
    textFull = textFull.replaceAll(';', '')
    textFull = textFull.replaceAll('\n', '')
    let listItem = textFull.split(' ')
    let stringText = ''
    let listYears = []
    let listCmnd = []
    if (listItem && listItem.length > 0) {
      listItem.map((value) => {
        let valueFirst = value.charAt(0)
        let valueLast = value.substring(1)
        let fullString = valueFirst.toLocaleUpperCase() + valueLast
        let checkNumber = parseFloat(fullString)
        if (fullString && !isNaN(checkNumber)) {
          let totalLength = fullString.length
          if (totalLength === 4) {
            listYears.push(fullString)
          } else {
            listCmnd.push(fullString)
          }
        }
        stringText = stringText + ' ' + fullString
      })
    }
    const listName = stringText.match(/([A-ZÀ-Ỹ][a-zà-ỹ]+(?:\s[A-ZÀ-Ỹ][a-zà-ỹ]+)*)/g);

    let fullname = ''
    let cmnd = ''
    let age = ''

    return {
      fullname1: fullname,
      cmnd1: cmnd,
      age1: age,
      fullname_version1: listName,
      cmnd_version1: listCmnd,
      age_version1: listYears,
    }
  }

  const makeRow5 = (value) => {
    let text = value?.[1]
    if (!text) {
      return null
    }
    let listText = text.split(' ')
    let diachi = ''
    listText = listText.filter((item) => {
      item = removeText(item)
      return item
    })

    listText.map((item) => {
      diachi = diachi + ' ' + item
    })

    return {
      diachi: diachi,
    }
  }

  const makeRowThuadat = (value) => {
    let checkDate = value?.[1] ? moment(value?.[1]).format('DD/MM/YYYY') : ''
    if (value?.[1] || value?.[2] || value?.[3] || value?.[4] || value?.[5] || value?.[6] || value?.[7] || value?.[8] || value?.[9] || value?.[10]) {
      return {
        ngaythangnamvao: checkDate !== 'Invalid date' ? checkDate : '',
        sothututhuadat: value?.[2] ? value?.[2] : '',
        sothutubando: value?.[3] ? value?.[3] : '',
        rieng: value?.[4] ? value?.[4] : '',
        chung: value?.[5] ? value?.[5] : '',
        mucdichsudung: value?.[6] ? value?.[6] : '',
        thoihansudung: value?.[7] ? value?.[7] : '',
        nguongocsudung: value?.[8] ? value?.[8] : '',
        sophathanhgcn: value?.[9] ? value?.[9] : '',
        sovaosocapgcnqsdd: value?.[10] ? value?.[10] : '',
      }
    }
    return null
  }

  const makeRowThaydoi = (value) => {
    if (value?.[1] || value?.[2] || value?.[3]) {
      return {
        sothututhuadat: value?.[1] ? value?.[1] : '',
        ngaythangnam: value?.[2] ? value?.[2] : '',
        noidung: value?.[3] ? value?.[3] : '',
      }
    }
    return null
  }

  const handleChangeData = (fiels) => {
    setLileList(fiels?.fileList)
  }

  return (
    <>
      <Upload fileList={fileList} action={'/'} onChange={(fiels) => handleChangeData(fiels)} maxCount={maxFile}>
        <Button icon={<UploadOutlined />}>Click to Upload excel</Button>
      </Upload>
      <Button
        type="primary"
        onClick={handleUpload}
        disabled={fileList.length === 0}
        style={{ marginTop: 16 }}
      >
        {uploading ? 'Uploading' : 'Start Upload'}
      </Button>
    </>
  )
}