import { axiosGET, axiosPOST } from '../../Helper/TypeAxios'
import { urlParseParams } from "../../Helper/helpFunction";

const routerRoot = 'parcelland';

export default class ParcelOfLand {
    getData(data) {
        const stringUrl = urlParseParams(data);
        return axiosGET(`/${routerRoot}/list?${stringUrl}`, data, {})
    }

    detailData(id) {
        return axiosGET(`/${routerRoot}/detail/${id}`)
    }

    updateData(id, data) {
        return axiosPOST(`/${routerRoot}/update/${id}`, data)
    }

    createData(data) {
        return axiosPOST(`/${routerRoot}/create`, data)
    }

    createDataThua(data) {
        return axiosPOST(`/${routerRoot}/create-multiple`, data, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
    }

    deleteData(id) {
        return axiosGET(`/${routerRoot}/delete/${id}`, {})
    }

    deleteDataVillage(id) {
        return axiosGET(`/${routerRoot}/delete_all_parcel_of_land/${id}`, {})
    }

    updateDataThua(id, data) {
        return axiosPOST(`/${routerRoot}/update/${id}`, data)
    }

    uploadFileExcel(id, data) {
        return axiosPOST(`/${routerRoot}/uploadFileExcel/${id}`, data)
    }
}
