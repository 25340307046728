import React, { useEffect, useState } from 'react'
import API from '../../service/API'
import { useHistory, useParams } from "react-router-dom";

export default function ExportWard() {
  const params = useParams();
  const id = params?.id;
  const [DataList, setDataList] = useState([])
  const [Params, setParams] = useState({
    page: 1,
    limit: 10,
    orderByName: 'name',
    orderBySort: 'asc',
    wards_id: id
  })

  const fetchData = async () => {
    const res = await API.Village.getData(Params)
    if (res?.status === 200) {
      setDataList(res)
    }

  }

  useEffect(() => {
    if (id) {
      fetchData()
    }
  }, [id])

  return (
    <div style={{ padding: 12 }}>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: 12, }}>
        {
          DataList?.data && DataList?.data.length > 0 ? DataList?.data.map((item, key) => {
            return (
              <div style={{ padding: 12, border: `2px solid rgb(196, 196, 196)` }} key={key}>
                <div style={{ fontSize: 18, fontWeight: '700', textAlign: 'center' }}>{item?.name}</div>
                <div style={{ display: 'flex', gap: 12, }}>
                  <button onClick={() => window.open(`http://export.sodiachinhbudang.xyz/?id=${item?.id}`)}>Danh sách thửa</button>
                  <button onClick={() => window.open(`http://export.sodiachinhbudang.xyz/report?id=${item?.id}`)}>Sổ địa chính điện tử</button>
                </div>
              </div>
            )
          }) : null
        }
      </div>
    </div>
  )
}
