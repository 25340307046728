import { ExclamationCircleOutlined, CheckCircleOutlined, HeartFilled, CopyOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Pagination, Row, Spin, Table, Tag, Upload, message, Input } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { RouterLink } from "../../constants/constants";
import API from "../../service/API";
import Search from './search';
import { routerRoot } from './contants';
import { useSelector } from 'react-redux'
import Home from '../PageExcel/Home'
import TableInfo from './TableInfo'

export default function List() {
  const urlParams = useParams()
  const id = urlParams?.id
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ data: [], total: 0 });
  const [params, setParams] = useState({ page: 1, limit: 10, orderByName: 'page_sheet', orderBySort: 'desc' });
  const history = useHistory();
  const [detail, setDetail] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dataSave, setDataSave] = useState({})
  const [detailEdit, setDetailEdit] = useState(null)
  const [detailEditSave, setDetailEditSave] = useState(null)
  const [modalViewThua, setModalViewThua] = useState(false);
  const [ModalConfirmPass, setModalConfirmPass] = useState(false)
  const [PasswordConfirm, setPasswordConfirm] = useState('')
  const [village, setVillage] = useState({})

  const columns = [
    {
      title: <strong>#</strong>,
      render: (text, record, index) => <div>Trang {record?.page_sheet}</div>,
    },
    {
      title: 'Địa phận',
      render: record => {
        return <div>
          <div>Xã: <span className='qshmfigade'>{record?.village_info?.wards_info?.name}</span></div>
          <div>Thôn: <span className='qshmfigade'>{record?.village_info?.name}</span></div>
        </div>
      }
    },
    {
      title: 'NGƯỜI SỬ DỤNG ĐẤT',
      render: record => {
        return <div style={{ width: 200 }}>
          <div>Chủ Hộ 1: {record?.fullname1} - {record?.age1} - {record?.cmnd1}</div>
          {record?.fullname2 ? <div>{record?.fullname2} - {record?.age2} - {record?.cmnd2}</div> : null}
          Địa chỉ: {record?.address}
          <div>
            <Button style={{ marginTop: 6 }} onClick={() => handeEditThua(record?.id)}>Sửa</Button>
          </div>
        </div>
      }
    },
    {
      title: 'THỬA ĐẤT',
      render: record => {
        return <div>
          {
            record?.thuadat ? record?.thuadat.map((item) => {
              return (
                <div className={`AMIaAVtBqX ${item?.checklinethua === 'throght-thua' ? 'dibwpioxhv' : ''}`}>
                  {
                    (!item?.sothututhuadat && !item?.sothutubando && !item?.sophathanhgcn) ? null : <>
                      <div className='CsjgxirKgP' onClick={() => window.open(`/admin/parcelland/print/${item?.id}`)}><CopyOutlined /></div>
                      <Tag color={"volcano"} className="esiqveqlal">
                        {item?.ngaythangnamvao ? <Tag className={`${item?.checklinethua === 'throght-thua' ? 'dibwpioxhv' : ''}`}>Ngày tháng năm vào: {item?.ngaythangnamvao}</Tag> : null}
                        {item?.sothututhuadat ? <Tag className={`${item?.checklinethua === 'throght-thua' ? 'dibwpioxhv' : ''}`}>Số Thứ tự: {item?.sothututhuadat}</Tag> : null}
                        {item?.sothutubando ? <Tag className={`${item?.checklinethua === 'throght-thua' ? 'dibwpioxhv' : ''}`}>Số Thứ tự từ bản đồ: {item?.sothutubando}</Tag> : null}
                        {item?.rieng ? <Tag className={`${item?.checklinethua === 'throght-thua' ? 'dibwpioxhv' : ''}`}>Diện tích riêng: {item?.rieng}</Tag> : null}
                        {item?.chung ? <Tag className={`${item?.checklinethua === 'throght-thua' ? 'dibwpioxhv' : ''}`}>Diện tích sử dụng chung: {item?.chung}</Tag> : null}
                        {item?.mucdichsudung ? <Tag className={`${item?.checklinethua === 'throght-thua' ? 'dibwpioxhv' : ''}`}>Mục đích sử dụng: {item?.mucdichsudung}</Tag> : null}
                        {item?.thoihansudung ? <Tag className={`${item?.checklinethua === 'throght-thua' ? 'dibwpioxhv' : ''}`}>Thời gian sử dụng: {item?.thoihansudung}</Tag> : null}
                        {item?.nguongocsudung ? <Tag className={`${item?.checklinethua === 'throght-thua' ? 'dibwpioxhv' : ''}`}>Nguồn gốc sử dụng: {item?.nguongocsudung}</Tag> : null}
                        {item?.sophathanhgcn ? <Tag className={`${item?.checklinethua === 'throght-thua' ? 'dibwpioxhv' : ''}`}>Số Phát Hành CNQ SDĐ: {item?.sophathanhgcn}</Tag> : null}
                        {item?.sovaosocapgcnqsdd ? <Tag className={`${item?.checklinethua === 'throght-thua' ? 'dibwpioxhv' : ''}`}>Số vào cấp GCN QSDĐ{item?.sovaosocapgcnqsdd}</Tag> : null}
                      </Tag>
                    </>
                  }

                </div>
              )
            }) : null
          }
        </div>
      }
    },
    {
      title: 'NHỮNG THAY ĐỔI TRONG QUÁ TRÌNH SỬ DỤNG ĐẤT VÀ GHI CHÚ',
      render: record => {
        return <div style={{ width: 400 }} className="">
          {
            record?.thaydoi ? record?.thaydoi.map((item) => {
              return (
                <div className={`amitupwtjq ${item?.checklinethua === 'throght-line' ? 'dibwpioxhv' : ''}`}>
                  {item?.thonlinktosheet && item?.thuadatlinktosheet ? <CheckCircleOutlined className='mxpefrcshf' /> : null}
                  {
                    (!item?.sothututhuadat && !item?.noidung) ? null : <Tag color={"volcano"} className="esiqveqlal">
                      {item?.sothututhuadat ? <Tag className={`${item?.checkline === 'throght-line' ? 'dibwpioxhv' : ''}`}>Số thứ tự thửa: {item?.sothututhuadat}</Tag> : null}
                      {item?.ngaythangnam ? <Tag className={`${item?.checkline === 'throght-line' ? 'dibwpioxhv' : ''}`}>Ngày tháng: {item?.ngaythangnam}</Tag> : null}
                      {item?.noidung ? <Tag onClick={() => {
                        handeEditThua(item?.thuadatlinktosheet)
                      }} className={`wtcygdslpc ${item?.checkline === 'throght-line' ? 'dibwpioxhv' : ''}`}>
                        Thông tin: {item?.noidung}
                      </Tag> : null}
                    </Tag>
                  }

                </div>
              )
            }) : null
          }
        </div>
      }
    },
    {
      title: 'Thao tác',
      render: record => {
        return (
          <div className="box-btn-list">
            <Button type={'danger'} onClick={() => handleDeleteItem(record?.id)}>Xóa</Button>
          </div>
        )
      },
    }
  ];

  const fetchVillage = async (params = {}) => {
    const res = await API.Village.getData(params);

    if (res?.status === 200) {
      setVillage(res)
    }
  };

  useEffect(() => {
    fetchVillage({ limit: 1000, page: 1, orderByName: 'name', orderBySort: 'asc' });
  }, [])

  const handleDeleteItem = async (id) => {
    await API[routerRoot].deleteData(id);
    fetch(params).then()
    alert("Xóa thành công")
  }

  const handeEditThua = async (id) => {
    if (id) {
      const res = await API[routerRoot].detailData(id);
      if (res?.status === 200) {
        setDetailEdit(res?.data)
        setTimeout(() => {
          setModalViewThua(true)
          setDetailEditSave(null)
        }, 800)
      }
    }
  }

  const handleUpdateThua = async () => {
    if (detailEditSave) {
      await API.ParcelOfLand.updateDataThua(detailEdit?.id, detailEditSave);
      fetch(params).then()
      // setModalViewThua(false)
      alert("Cập nhật thành công")
    } else {
      alert("Nhấn lưu thông tin để tiếp tục")
    }
  }

  useEffect(() => {
    fetch(params).then()
    fetchDetail().then()
  }, [params]);

  const fetch = async (params = {}) => {
    if (id) {
      params.village_id = id
    }
    setLoading(true);
    const res = await API[routerRoot].getData(params);
    if (res?.status === 200) {
      setData(res)
    }
    setLoading(false);
    return true
  };

  const fetchDetail = async () => {
    if (id) {
      const res = await API.Village.detailData(id);
      if (res?.status === 200) {
        setDetail(res?.data)
      }
    }
  }

  const handleSearch = (values) => {
    setParams({ ...params, ...values })
  }

  const handlePage = (page, perPage) => {
    setParams({ ...params, page: page, limit: perPage })
  };

  const handleCreateOrUpdateData = async () => {
    setLoading(true);
    const data = { village_id: id, dataSave: dataSave }
    const jsonString = JSON.stringify(data);
    const blob = new Blob([jsonString], { type: "application/json" });
    const formData = new FormData();

    formData.append('files', blob)
    const res = await API[routerRoot].createDataThua(formData);

    if (res?.status === 200) {
      const resData = await fetch(params).then()
      setLoading(false)
      if (resData) {
        alert('Thêm thông tin thành công')
      }
      // setIsModalVisible(false)
    }
  }

  const handleCreateThua = async () => {
    if (id) {
      setIsModalVisible(true);
    } else {
      history.push(RouterLink.Village.List)
      if (id) {
        params.village_id = id
      }
      fetch(params).then()
    }
  }

  const handleDeleteAll = async () => {
    setLoading(true);
    if (PasswordConfirm === 'sodiachinhbudang2023') {
      alert("Xóa thành công")
      await API[routerRoot].deleteDataVillage(id);
      fetch(params).then()
      setModalConfirmPass(false)
      setPasswordConfirm('')
      setLoading(false);
    } else {
      alert("Đã có lỗi xảy ra!")
      setLoading(false);
    }
  }

  return (
    <div>
      <Search data={(value) => handleSearch(value)} />
      {detail?.name ? <div className="QBSAiqWULo">{detail?.name}</div> : null}
      <Row style={{ marginBottom: 10 }}>
        <Col><Button type={'primary'} onClick={() => handleCreateThua()}>Tạo mới</Button></Col>
        <Col style={{ marginLeft: 4 }}>
          <Button type={'danger'} onClick={() => setModalConfirmPass(true)}>Xóa toàn bộ</Button>
        </Col>
      </Row>
      <Spin spinning={loading}>
        <Row justify={'center'} style={{ marginTop: '10px' }}>
          <Col>
            <Pagination
              current={params.page}
              onChange={(page, perPage) => handlePage(page, perPage)}
              total={data?.total}
              showSizeChanger={true}
              defaultPageSize={params?.perPage}
              pageSizeOptions={['20', '50', '100']}
            />
          </Col>
        </Row>
        <Table
          dataSource={data?.data}
          columns={columns}
          rowKey={'id'}
          scroll={{ x: 1200 }}
          pagination={false}
        />
        <Row justify={'center'} style={{ marginTop: '10px' }}>
          <Col>
            <Pagination
              current={params.page}
              onChange={(page, perPage) => handlePage(page, perPage)}
              total={data?.total}
              showSizeChanger={true}
              defaultPageSize={params?.perPage}
              pageSizeOptions={['20', '50', '100']}
            />
          </Col>
        </Row>
      </Spin>
      <Modal title="Thêm thửa" width={'100%'} footer={false} visible={isModalVisible} closable={false}
        onOk={() => setIsModalVisible(!isModalVisible)} onCancel={() => setIsModalVisible(!isModalVisible)}>
        <Button onClick={() => handleCreateOrUpdateData()} className="vowvrqnxli">Hoàn thành nhập</Button>
        <Home dataSave={dataSave} dataInsert={(value) => setDataSave(value)} />
      </Modal>
      <Modal title="Xem thông tin hoặc chỉnh sửa thửa" width={'100%'} footer={false} visible={modalViewThua} closable={false}
        onOk={() => setModalViewThua(!modalViewThua)} onCancel={() => setModalViewThua(!modalViewThua)}>
        <Button onClick={() => handleUpdateThua()}>Hoàn Thành Chỉnh Sửa</Button>
        <TableInfo village={village} item={detailEdit} UpdateData={(value) => setDetailEditSave(value)} />
      </Modal>
      <Modal title="Xác nhận xóa toàn bộ" footer={false} visible={ModalConfirmPass} closable={false}
        onOk={() => setModalConfirmPass(!ModalConfirmPass)} onCancel={() => setModalConfirmPass(!ModalConfirmPass)}>
        <Input.Password placeholder='sodiachinhbudang2023' style={{ marginBottom: 24 }} value={PasswordConfirm} onChange={(event) => setPasswordConfirm(event.target.value)} />
        <Button onClick={() => handleDeleteAll()}>Xác nhận</Button>
      </Modal>
    </div>
  )
}
