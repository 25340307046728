import Auth from "./Auth";

import ParcelOfLand from "./API/ParcelOfLand";
import ParcellandInformation from "./API/ParcellandInformation";
import Village from "./API/Village";
import Wards from "./API/Wards";

const API = {
    Auth: new Auth(),
    Village: new Village(),
    Wards: new Wards(),
    ParcelOfLand: new ParcelOfLand(),
    ParcellandInformation: new ParcellandInformation(),
};

export default API
