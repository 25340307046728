import {axiosGET, axiosPOST} from '../../Helper/TypeAxios'
import {urlParseParams} from "../../Helper/helpFunction";
const routerRoot = 'wards';

export default class Wards {
    getData(data) {
        const stringUrl = urlParseParams(data);
        return axiosGET(`/${routerRoot}/list?${stringUrl}`, data, {})
    }

    detailData(id) {
        return axiosGET(`/${routerRoot}/detail/${id}`)
    }

    updateData(id, data) {
        return axiosPOST(`/${routerRoot}/update/${id}`, data)
    }

    createData(data) {
        return axiosPOST(`/${routerRoot}/create`, data)
    }
}
